.footer-container {
    background-color: var(--white);
    height: 100px;
}

.footer-content {
    max-width: 1350px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
}

.copyright {
    color: var(--black);
    font-size: 14px;
}

@media screen and (min-width: 1300px) {
    .footer-content {
        justify-content: flex-start;
        padding-left: 15px;
    }
}