.ux-ui-project-container {
    /* background-color: tomato; */
    margin: 50px auto;
    /* height: 1000px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1350px;
    width: 100%;
    /* background-color: red; */
}

.project-content {
    padding: 0 15px;
    margin: 50px 0;
}

.project-header {
    /* background-color: turquoise; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    max-width: 600px;
    margin: 0 auto;
    padding: 30px 0;
    

}

.bar {
    background-color: var(--black);
    height: 3px;
    width: 45px;
    margin-right: 20px;
}

.project {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-thumbnail {
    min-width: 340px;
    max-width: 600px;
    width: 100%;
}

.project-desc {
    /* background-color: violet; */
    min-width: 340px;
    font-size: 16px;
    line-height: 2rem;
    max-width: 600px;
    width: 100%;
    padding-top: 30px
}

@media screen and (min-width: 768px) {
    .frontend-project-container {
      /* height: 1500px; */

    }
}

@media screen and (min-width: 1300px) {
    .ux-ui-project-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(340px, 600px));
        justify-content: space-between;
        height: auto;

    }

    .project-desc {
        /* background-color: violet; */
        min-width: 340px;
        line-height: 2rem;
        max-width: 600px;
        width: 100%;
    }
}