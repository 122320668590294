* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {

    --blue: #1d9bf0;
    --black: #0f1419;
    --white: #fff;
    font-family: 'Poppins', sans-serif;
    position: relative;

}

