.background-img {
    background-image: url('../../images/blue_wave.svg');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    height: 100px;
    width: 100%;
    top: 500px;
    background-color: var(--white);
    z-index: -1;
}

.header-container {
    background-color: var(--blue);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
    position: relative;
}

.header-content {
    max-width: 1350px;
    margin: 0 auto;
}

.header-name {
    /* background-color: Sienna; */
    width: inherit;
    text-align: center;
    padding: 50px 0;
    font-weight: 300;
    font-size: 65px;
    color: var(--white);
}

.header-quote-container {
    /* background-color: Fuchsia; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    max-width: 500px;
    min-width: 340px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.header-left-quote {
    /* background-color: Wheat; */
    font-size: 35px;
    color: var(--white);
}

.header-quote {
    /* background-color: DarkSlateGray; */
    font-size: 30px;
    font-weight: 300;
    width: 100%;
    padding-left: 50px;
    color: var(--white);
    text-transform: capitalize;
}
