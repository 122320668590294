.testimonial-outer-container {
    background-color: var(--blue);
    color: var(--white)
}

.testimonial-container {
    /* background-color: orange; */
    margin: 100px auto 0;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    max-width: 1100px;
}

.testimonial-header {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.testimonial-content {
    /* background-color: orchid; */
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 15px;
    max-width: 600px;
    margin: 0  auto;
    
}

.testimonial-left-quote {
    font-size: 25px;
}

.client-message {
    /* background-color: lemonchiffon; */
    padding: 15px;
    font-size: 16px;
}

.client-name {
    padding: 10px 15px;
}

.stars-container {
    /* background-color: red; */
    display: flex;
    align-items: center;
    height: 30px;
    padding: 10px 15px;
}

.client-star-review {
    font-size: 20px;
}

@media screen and (min-width: 1300px) {
    .testimonial-content {
        height: 300px;
        flex-direction: row;
        align-items: center;
        max-width: inherit;
    } 

    .testimonial-content div {
        padding: 0 15px;
    }


}