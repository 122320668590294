.main-container {
    /* background-color: #333; */
    margin: 0px 0 100px;
}

.tab-container {
    /* background-color: lightcoral; */
    display: flex;
    width: 400px;
    justify-content: space-between;
    margin: 150px auto 0;
    font-size: 16px;
}


.frontend-dev-tab {
    text-decoration: none;
    color: var(--blue);
}

.ux-ui-tab {
    text-decoration: none;
    color: var(--black);
}

.graphic-design-tab {
    text-decoration: none;
    color: var(--black);
}

@media screen and (min-width: 1300px) {
    .tab-container {
        width: 100%;
        max-width: 1350px;
        justify-content: flex-start;
        padding: 0 15px
    }

    .ux-ui-tab, .graphic-design-tab {
        padding-left: 40px;
    }

 
}